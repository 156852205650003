import { mapGetters } from 'vuex';
import { isAdmin } from '@/lib/helper';
export default {
  name: 'OfferPrices',
  props: {
    prices: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    partnerPrices: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: { ...mapGetters('user', ['role'])
  },
  methods: {
    isAdmin
  }
};