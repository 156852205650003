import OfferPrices from '@/components/Offers/OfferPrices';
import PartnerMeny from '@/components/Partners/PartnerMenu';
import FormInput from '@/components/FormInput';
export default {
  name: 'OfferCard',
  components: {
    OfferPrices,
    PartnerMeny,
    FormInput
  },
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  emits: ['selectOffer'],

  data() {
    return {
      expanded: false
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('../../assets/partners/' + imageUrl);
    },

    expandBody() {
      this.expanded = !this.expanded;

      if (this.expanded) {
        document.getElementById(`offer-card-${this.offer.id}`).scrollIntoView({
          behavior: 'smooth'
        });
      }
    },

    selectOffer() {
      this.$emit('selectOffer', this.offer);
    }

  }
};