export const isAdmin = (role) => {
  return role === 'ADMIN';
};

export const isPartner = (role) => {
  return role === 'PARTNER';
};

export const isCustomer = (role) => {
  return role === 'CUSTOMER';
};
