import { mapGetters, mapActions } from 'vuex';
import SearchOffers from '@/fragments/SearchOffers';
import OfferCard from '@/components/Offers/OfferCard';
import Loading from '@/components/Loading';
export default {
  name: 'Offers',
  components: {
    SearchOffers,
    OfferCard,
    Loading
  },

  data() {
    return {
      onlyPreferredPartnerSelected: false
    };
  },

  computed: { ...mapGetters('offers', ['offers', 'preferredPartner', 'loading', 'invalidSearch', 'error']),

    shouldShowOnlyPreferredPartner() {
      return this.preferredPartner && this.onlyPreferredPartnerSelected;
    },

    processedOffers() {
      return this.shouldShowOnlyPreferredPartner ? this.offers.filter(offer => offer.id === this.preferredPartner) : this.offers;
    }

  },

  mounted() {
    this.onlyPreferredPartnerSelected = !!this.preferredPartner;
  },

  methods: { ...mapActions('offers', ['submitSearchForm']),

    selectOffer(offer) {
      this.$store.dispatch('offers/selectOffer', offer).then(() => {
        this.$router.push({
          path: '/sammanfattning'
        });
      });
    },

    toggleOnlyPreferredPartnerSelected() {
      this.onlyPreferredPartnerSelected = !this.onlyPreferredPartnerSelected;
    },

    goBack() {
      this.$router.push({
        path: '/'
      });
    }

  }
};